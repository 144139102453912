<template>
  <v-container>
    <div>
      <CRUDCriteria
        :showReset="true"
        :showSearch="false"
        >
        <template v-slot:criteriaRows>
          <v-row>
            <v-col cols="3">
              <FySelect
                id="tmc-fund-criteria-fy"
                label="By FY"
                v-model="criteriaState.fiscalYear"
                :fyRange="fiscalYearRange"
                @input="searchByCriteria"
              />
            </v-col>
            <v-col cols="3">
              <ClarionDateControl
                v-model="criteriaState.registerDate"
                :isRequired="false"
                :label="`As of Date`"
                :isIsoDate="true"
                @blurred="searchByCriteria"
              />
            </v-col>
            <v-col cols="3">
              <v-btn color="primary"
                rounded
                @click="gatherInformation">
                Gather Information
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </CRUDCriteria>
      <CRUDList
        :canEdit="true"
        :canSave="true"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :createNewItem="newItemDialog"
        :defaultSortBy="sortBy"
        :loadingData="loading"
        :loadingText="loadingText"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsertItem"
        toolbarTitle="Fund Reconciliation"
      >
        <template v-slot:[`editedItem`]="{}">
          <FundReconciliationEditedItem
          :baseCrudKey="baseCrudKey"
          />
        </template>
        <template v-slot:[`additionalTools`]="{}"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mx-2" color="primary"
              fab icon small
              v-bind="attrs"
              v-on="on"
              @click="ALGLReconBulkPrint"
            >
              {{icons.mdiPrinter}}
            </v-icon>
          </template>
            <span>Print AL/GL Balance Report</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
        <template>
          <v-checkbox
            :isRequired="false"
            label="Print Report Details"
            v-model="printDetails"
          />
        </template>
      </template>
      </CRUDList>
    </div>
   </v-container>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  mdiPrinter,
} from '@mdi/js';
import CRUDList from '../../../common/base/crud/CRUDList.vue';
import CRUDCriteria from '../../../common/base/crud/CRUDCriteria.vue';
import ClarionDateControl from '../../../common/ClarionDateControl.vue';
import FundReconciliationEditedItem from './FundReconciliationEditedItem.vue';
import FySelect from '../../../common/FySelect.vue';
import { amountToNumber, extractId } from '../../../../util/shared/vue-global';
import {
  toFiscalYear,
  calculateFiscalYears,
  toIsoDate,
} from '../../../../util/shared/tmc-global';
import { clients } from '../../../../util/clients';
import { amountFields } from './fundReconciliation.util';

const getHeaders = () => {
  const headers = [
    { text: 'Fund', value: 'fundDesc' },
    {
      text: 'GL Balance', value: 'glBalance', formatter: 'amount', align: 'right',
    },
    {
      text: 'GL Adjustments', value: 'glAdjustments', formatter: 'amount', align: 'right',
    },
    {
      text: 'GL Adjustments Bal', value: 'glAdjustmentsBal', formatter: 'amount', align: 'right',
    },
    {
      text: 'Appr Balance', value: 'apprBalance', formatter: 'amount', align: 'right',
    },
    {
      text: 'Appr Adjustments', value: 'apprAdjustments', formatter: 'amount', align: 'right',
    },
    {
      text: 'Appr Adjustments Bal', value: 'apprAdjustmentsBal', formatter: 'amount', align: 'right',
    },
    {
      text: 'Variance', value: 'variance', formatter: 'amount', align: 'right',
    },
  ];
  return headers;
};
const extractGLFundMongoId = (item) => item && item.fund && item.fund._id;
const extractGLFundNo = (item) => item && item.fund && item.fund.fund;
const extractGLFundDesc = (item) => item && item.fund && item.fund.description;
const { backendRest } = clients.direct;

export default {
  name: 'FundReconciliation',
  components: {
    CRUDCriteria,
    CRUDList,
    ClarionDateControl,
    FundReconciliationEditedItem,
    FySelect,
  },
  data: () => ({
    baseCrudKey: 'fundReconciliation',
    printDetails: false,
    headers: [],
    sortBy: [
      {
        column: 'fund',
        direction: 'asc',
      },
    ],
    icons: {
      mdiPrinter,
    },
  }),
  created() {
    this.initCriteria();
    this.headers = getHeaders();
    this.loadFiscalRegYears();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'index',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      loading: (state) => state.gridLoading,
      loadingText: (state) => state.gridLoadingText,
      fiscalYearRange: (state) => {
        const registerdYears = state.war.util.fundReconciliation.regYears || {};
        return calculateFiscalYears(registerdYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapActions([
      'reAuth',
    ]),
    ...mapActions('war/util/fundReconciliation', [
      'loadfundsRecon',
      'upsertfundRecon',
      'loadFiscalRegYears',
      'loadgatherInformation',
    ]),
    ...mapActions('war/util/glFund', [
      'loadGLFunds',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      this.updateCriteriaForReq();
      const criteria = this.criteriaState;
      this.loadfundsRecon({
        criteria,
        baseCrudKey,
        postProccessFundRecon: (dataItems) => dataItems.map((dataItem) => ({
          ...dataItem,
          fund: extractGLFundMongoId(dataItem),
          fundNo: extractGLFundNo(dataItem),
          fundDesc: extractGLFundDesc(dataItem),
        })),
      });
    },
    updateCriteriaForReq() {
      if (this.criteriaState.registerDate) {
        this.criteriaState.registerDate = toIsoDate(this.criteriaState.registerDate);
      } else {
        this.criteriaState.registerDate = toIsoDate(this.todaysDate);
      }
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    upsertItem(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      // format all amount fields to number
      amountFields.forEach((element) => {
        updatedItem[element] = amountToNumber(updatedItem[element]);
      });
      delete updatedItem.fundNo;
      delete updatedItem.fundDesc;
      if (updatedItem.fund) {
        updatedItem.fund = extractId(updatedItem.fund);
      }
      if ((updatedItem.details || []).length > 0) {
        updatedItem.details = updatedItem.details.map((element) => {
          const amount = amountToNumber(element.amount);
          return {
            ...element,
            amount,
          };
        });
      }
      this.upsertfundRecon({
        item: updatedItem,
        baseCrudKey,
        postProccessFundRecon: (dataItems) => dataItems.map((dataItem) => ({
          ...dataItem,
          fund: extractGLFundMongoId(dataItem),
          fundNo: extractGLFundNo(dataItem),
          fundDesc: extractGLFundDesc(dataItem),
        })),
      });
      return true;
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        registerDate: this.todaysDate,
        fiscalYear: toFiscalYear(new Date(this.todaysDate)),
        fund: null,
        details: [],
      };
      this.loadGLFunds({
        criteria: {
          fiscalYear: newItem.fiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'GLFundList',
      });
      return newItem;
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
      this.criteriaState.registerDate = toIsoDate(this.todaysDate);
    },
    gatherInformation() {
      this.loadgatherInformation({
        criteria: {
          fiscalYear: this.criteriaState.fiscalYear,
          registerDate: this.criteriaState.registerDate,
        },
        baseCrudKey: 'fundReconciliation',
        postProccessFundRecon: (dataItems) => dataItems.map((dataItem) => ({
          ...dataItem,
          fund: extractGLFundMongoId(dataItem),
          fundNo: extractGLFundNo(dataItem),
          fundDesc: extractGLFundDesc(dataItem),
        })),
      });
    },
    async ALGLReconBulkPrint() {
      const { jwt } = await this.reAuth();
      const url = `${backendRest.defaults.baseURL}/war/warrant-al-gl-recon-balance?fiscalYear=${this.criteriaState.fiscalYear}&registerDate=${this.criteriaState.registerDate}&printDetails=${this.printDetails}&token=${jwt}`;
      window.open(url, '_blank');
    },
  },
};
</script>
