var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 FundRecDetails",attrs:{"dense":"","items":_vm.editedItem,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","fab":"","icon":"","x-small":""},on:{"click":_vm.addOne}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.fundReconTypes,"item-text":"description","item-value":"name","label":"Type","dense":"","single-line":""},on:{"blur":_vm.somethingChanged},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","label":"Description","single-line":""},on:{"blur":_vm.somethingChanged},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"fund-rec-amount",attrs:{"dense":"","single-line":"","label":"Amount"},on:{"blur":function($event){_vm.somethingChanged(); _vm.updateBalance()}},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},{key:"item.memo",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","label":"Memo"},on:{"blur":_vm.somethingChanged},model:{value:(item.memo),callback:function ($$v) {_vm.$set(item, "memo", $$v)},expression:"item.memo"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","tabindex":"-1"},on:{"click":function($event){_vm.removeOne(item); _vm.updateBalance()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"blue--text"},[_c('td',{attrs:{"colspan":"2","id":"total-trans-amount"}},[_vm._v(" Total : $"+_vm._s(_vm.amountFormat(_vm.sumAmount))+" ")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }