<template>
  <div :class="allClasses">
    <v-text-field
      v-if="byClassic"
      :value="value"
      @input="val => changed(val)"
      @blur="blurred"
      @keydown="shortcutHandler"
      :rules="clarionDateValidation()"
      :label="label"
      class="ClarionDateInput"
      :disabled="isDisabled"
      :readonly="isReadOnly"
    >
      <template v-slot:prepend>
        <v-tooltip top right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small
              tabindex="-1"
              v-bind="attrs"
              v-on="on"
              @click="toggle"
            >{{calendarType}}</v-icon>
          </template>
          <span>Toggle between date controls</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <v-text-field id="vuetify-date-input-text"
      v-if="!byClassic"
      :value="value"
      @input="val => changed(val)"
      @blur="blurred"
      type="date"
      class="VuetifyDateInput"
      :label="label"
      :disabled="isDisabled"
      :readonly="isReadOnly"
    >
      <template v-slot:prepend>
        <v-tooltip top right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small
              v-bind="attrs"
              v-on="on"
              class="toggle"
              @click="toggle"
            >{{calendarType}}</v-icon>
          </template>
          <span>Toggle between date controls</span>
        </v-tooltip>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  mdiCalendarCursor,
  mdiCalendarEdit,
} from '@mdi/js';

import {
  destructureDate,
  toIsoDate,
} from '../../util/shared/tmc-global';

export default {
  name: 'ClarionDateControl',
  components: {},
  data: () => ({
    byClassic: false,
  }),
  props: {
    classes: {
      type: String,
      default: '',
    },
    value: [String, Number], // can handle null and undefined, eg. paid date
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isIsoDate: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => ([]),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'dateControlClassic',
    ]),
    calendarType() {
      return this.byClassic ? mdiCalendarCursor : mdiCalendarEdit;
    },
    allClasses() {
      return [
        'ClarionDateControl',
        ...this.classes.split(','),
      ];
    },
  },
  created() {
    this.byClassic = this.dateControlClassic;
  },
  methods: {
    ...mapActions([
      'flashInfo',
    ]),
    toggle() {
      this.$emit('input', '');
      this.byClassic = !this.byClassic;
    },
    changed(newValue) {
      // console.log(`newValue: ${newValue}`);
      this.$emit('input', newValue);
    },
    blurred() {
      let dateStr;
      if (this.byClassic) {
        dateStr = this.clarionDateManipulation();
      } else {
        dateStr = this.value;
      }
      // This is a custom event for business logic hooks
      if ((dateStr || '').length > 0) {
        const { year, month, day } = destructureDate(dateStr);
        this.$emit('blurred', `${year}-${month}-${day}`);
      }
    },
    // pre-validation custom logic processing
    clarionDateManipulation() {
      // value.match(/([0-9]{4})[/\\-]{1}([0-9]{2})[/\\-]{1}([0-9]{2})/);
      const { year, month } = destructureDate(new Date());
      const { value } = this;
      if ((value || '').length === 0) {
        return '';
      }

      // console.info(`clarionDateManipulation: ${value}`);

      const isDayOfMonth = value.match(/^[./\\-]([0-9]+)$/);
      if (isDayOfMonth) {
        const newDateStr = `${month}-${isDayOfMonth[1]}-${year}`;
        return this.roundTripDate(newDateStr);
      }

      //  Special case when only single digit is entered show current fiscal year start date.
      const isBareD = value.match(/^[0-9]{1,2}$/);
      if (isBareD) {
        const newDateStr = `7-1-${year}`;
        return this.roundTripDate(newDateStr);
      }

      const isBareMonthDay = value.match(/^([0-9]{2})([0-9]{2})$/);
      if (isBareMonthDay) {
        const newDateStr = `${isBareMonthDay[1]}-${isBareMonthDay[2]}-${year}`;
        return this.roundTripDate(newDateStr);
      }

      const isBareMdy = value.match(/^([0-9]{2})([0-9]{2})([0-9]{2})$/);
      if (isBareMdy) {
        const newDateStr = `${isBareMdy[1]}-${isBareMdy[2]}-20${isBareMdy[3]}`;
        return this.roundTripDate(newDateStr);
      }

      const isBareFullMdy = value.match(/^([0-9]{2})([0-9]{2})([0-9]{4})$/);
      if (isBareFullMdy) {
        const checkYear = parseFloat(isBareFullMdy[3]);
        // note to future Y3K developer: sorry / not sorry :)
        if (checkYear > 1500 && checkYear < 3500) {
          const newDateStr = `${isBareFullMdy[1]}-${isBareFullMdy[2]}-${isBareFullMdy[3]}`;
          return this.roundTripDate(newDateStr);
        }
        const isBareFullYmd = value.match(/^([0-9]{4})([0-9]{2})([0-9]{2})$/);
        if (isBareFullYmd) {
          const newDateStr = `${isBareFullYmd[2]}-${isBareFullYmd[3]}-${isBareFullYmd[1]}`;
          return this.roundTripDate(newDateStr);
        }
        this.bail();
        return '';
      }

      const isBareMMDD = value.match(/^([0-9]{1,2})(\\|\.|\/|-)([0-9]{1,2})$/);
      //  to check regex for 08.23,8-23,08/23
      if (isBareMMDD) {
        const newDateStr = `${isBareMMDD[1]}-${isBareMMDD[3]}-${year}`;
        return this.roundTripDate(newDateStr);
      }

      const isBareMMDDYY = value.match(/^([0-9]{1,2})(\\|\.|\/|-)([0-9]{1,2})(\\|\.|\/|-)([0-9]{1,2})$/);
      if (isBareMMDDYY) {
        const newDateStr = `${isBareMMDDYY[1]}-${isBareMMDDYY[3]}-20${isBareMMDDYY[5]}`;
        return this.roundTripDate(newDateStr);
      }

      return this.roundTripDate();
    },
    // catch-all, standard JS formatting
    roundTripDate(newValueIfAny = undefined) {
      const value = newValueIfAny || this.value;
      // console.info(`round trip: ${newValueIfAny} vs ${this.value}`);
      let newDateStr;
      const { year, month, day } = destructureDate(value);
      if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
        this.bail();
        return '';
      }
      if (this.isIsoDate) {
        newDateStr = toIsoDate(value);
      } else {
        newDateStr = `${month}-${day}-${year}`;
      }
      this.$emit('input', newDateStr);
      return newDateStr;
    },
    bail() {
      this.$emit('input', '');
      const errMsg = `Unable to understand ${this.value || ''}`;
      this.$emit('error', errMsg);
      this.flashInfo(errMsg);
    },
    clarionDateValidation() {
      const internalRules = [
        (value) => {
          // console.log(`clarionDateValidation (reqd): ${value}`);
          const hasValue = !!value;
          if (!hasValue) {
            return (this.isRequired) ? 'Required' : true;
          }
          return true;
        },
        (value) => {
          // console.log(`clarionDateValidation (regex): ${value}`);
          if (this.isRequired || !!value) {
            const valueStr = value.toString();
            const isMatch = valueStr.match(/^[0-9]+[/\\-][0-9]+[/\\-][0-9]+$/);
            if (!isMatch && valueStr.length > 0) {
              return 'Unexpected characters, or date is not in MM-DD-YYYY format.';
            }
          }
          return true;
        },
      ];
      return [
        ...internalRules,
        ...(this.rules || []),
      ];
    },
    shortcutHandler(e) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        const upDownDate = new Date(this.value);
        if (e.key === 'ArrowDown') {
          upDownDate.setDate(upDownDate.getDate() - 1);
        }
        if (e.key === 'ArrowUp') {
          upDownDate.setDate(upDownDate.getDate() + 1);
        }
        const { year, month, day } = destructureDate(upDownDate);
        this.roundTripDate(`${month}-${day}-${year}`);
      }
      if (e.key === 'Enter') {
        this.blurred();
      }
    },
  },
};
</script>
