export const amountFields = [
  'glBalance',
  'glAdjustments',
  'glAdjustmentsBal',
  'apprBalance',
  'apprAdjustments',
  'apprAdjustmentsBal',
  'variance',
];

export default {
  amountFields,
};
