<template>
   <v-container>
    <v-row>
      <v-col cols="4">
        <v-select
          class="war-trans-fund"
          label="Fund"
          v-model="editedItem.fund"
          :items="funds"
          item-value="_id"
          item-text="fund"
          readonly
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Fiscal Year"
          v-model.number="editedItem.fiscalYear"
          type="number"
          readonly
          @keydown="getFunds"
        ></v-text-field>
      </v-col>
      <v-col col="4">
        <ClarionDateControl
          :isReadOnly="true"
          :isIsoDate="true"
          v-model="editedItem.registerDate"
          label="Register Date"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="editedItem.glBalance"
          label="GL Balance"
          readonly
          @blur="roundAmount('glBalance')"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="editedItem.glAdjustments"
          label="GL Adjustments"
          readonly
          @blur="roundAmount('glAdjustments')"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="editedItem.glAdjustmentsBal"
          label="GL Adjustments Balance"
          readonly
          @blur="roundAmount('glAdjustmentsBal')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="editedItem.apprBalance"
          label="Appr Balance"
          readonly
          @blur="roundAmount('apprBalance')"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="editedItem.apprAdjustments"
          label="Appr Adjustments"
          readonly
          @blur="roundAmount('apprAdjustments')"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="editedItem.apprAdjustmentsBal"
          label="Appr Adjustments Balance"
          readonly
          @blur="roundAmount('apprAdjustmentsBal')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="editedItem.variance"
          label="Variance"
          readonly
          @blur="roundAmount('variance')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <FundRecDetails
          v-model="editedItem.details"
          @updateBalance="updateBalance"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ClarionDateControl from '../../../common/ClarionDateControl.vue';
import FundRecDetails from './FundRecDetails.vue';
import { amountFormat, amountToNumber, checkStringEmptiness } from '../../../../util/shared/vue-global';
import { amountFields } from './fundReconciliation.util';

const glFundCrudKey = 'GLFundList';
export default {
  name: 'FundsReconciliationEditedItem',
  components: {
    ClarionDateControl,
    FundRecDetails,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'fundRecon-dialog',
    },
  },
  created() {
    this.getFunds();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'items',
      'index',
    ]),
    editedItem: {
      get() {
        const item = this.item(this.baseCrudKey);
        amountFields.forEach((f) => {
          item[f] = amountFormat(amountToNumber(item[f]));
        });
        return item;
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    funds() {
      const glFund = this.items(glFundCrudKey) || [];
      return [{ _id: null, fund: '' }, ...glFund];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    ...mapActions('war/util/glFund', [
      'loadGLFunds',
    ]),
    async getFunds() {
      const { fiscalYear } = this.editedItem;
      if (!(checkStringEmptiness(fiscalYear))) {
        await this.loadGLFunds({
          criteria: { fiscalYear: this.editedItem.fiscalYear },
          baseCrudKey: 'GLFundList',
        });
      }
      return true;
    },
    roundAmount(field) {
      this.editedItem[field] = amountFormat(amountToNumber(this.editedItem[field]));
    },
    sumOfGLBalAndAdj() {
      this.editedItem.glAdjustmentsBal = amountFormat(amountToNumber(this.editedItem.glBalance)
      + amountToNumber(this.editedItem.glAdjustments));
    },
    sumOfApprBalanceAndAdj() {
      this.editedItem.apprAdjustmentsBal = amountFormat(amountToNumber(this.editedItem.apprBalance)
      + amountToNumber(this.editedItem.apprAdjustments));
    },
    calculateVariance() {
      this.editedItem.variance = amountFormat(amountToNumber(this.editedItem.glAdjustmentsBal)
      - amountToNumber(this.editedItem.apprAdjustmentsBal));
    },
    updateBalance() {
      const adjbalance = {
        glAdj: 0,
        appAdj: 0,
      };
      (this.editedItem.details || []).forEach((element) => {
        if (element.type === 'GENERALLEDGER') {
          adjbalance.glAdj += amountToNumber(element.amount);
        } else if (element.type === 'APPROPRIATION') {
          adjbalance.appAdj += amountToNumber(element.amount);
        }
      });
      this.editedItem.glAdjustments = amountFormat(adjbalance.glAdj);
      this.editedItem.apprAdjustments = amountFormat(adjbalance.appAdj);
      this.sumOfGLBalAndAdj();
      this.sumOfApprBalanceAndAdj();
      this.calculateVariance();
    },
  },
};
</script>
