<template>
  <v-select class="tmc-fy-select"
    id="tmcFySelect"
    :value="value"
    @change="val => changed(val)"
    :items="fyRange"
    item-text="name"
    item-value="year"
    :label="`By FY`"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true, // required to v-bind it
    },
    fyRange: {
      type: Array,
      default: () => [{ range: {}, year: 0, name: '(all years)' }],
    },
  },
  methods: {
    changed(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>
