<template>
  <v-data-table
    dense
    :items="editedItem"
    :headers="headers"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 FundRecDetails"
  >
    <template v-slot:top>
      <div class="d-flex justify-end">
        <v-btn
          class="mx-2" color="primary"
          fab icon x-small
          @click="addOne"
        >
          <v-icon>
            {{icons.mdiPlus}}
          </v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:[`item.type`]="{ item }">
      <v-select
        :items="fundReconTypes"
        item-text="description"
        item-value="name"
        v-model="item.type"
        label="Type"
        dense
        single-line
        @blur="somethingChanged"
      ></v-select>
    </template>

    <template v-slot:[`item.description`]="{ item }">
      <v-text-field
        dense
        label="Description"
        v-model="item.description"
        single-line
        @blur="somethingChanged"
      ></v-text-field>
    </template>

    <template v-slot:[`item.amount`]="{ item }">
      <v-text-field
        dense
        single-line
        class="fund-rec-amount"
        label="Amount"
        v-model="item.amount"
        @blur="somethingChanged(); updateBalance() "
      ></v-text-field>
    </template>

    <template v-slot:[`item.memo`]="{ item }">
      <v-text-field
        dense
        single-line
        label="Memo"
        v-model="item.memo"
        @blur="somethingChanged"
      ></v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small class="mr-2"
        tabindex="-1"
        @click="removeOne(item); updateBalance()"
      >
        {{icons.mdiDelete}}
      </v-icon>
    </template>
    <template slot="body.append">
      <tr class="blue--text">
          <td colspan="2" id="total-trans-amount">
            Total : ${{ amountFormat(sumAmount) }}
          </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import {
  focusAmount,
  amountFormat,
  amountToNumber,
} from '../../../../util/shared/vue-global';

const defaultItem = {
  type: undefined,
  description: null,
  amount: 0,
  memo: null,
};
export default {
  name: 'FundRecDetails',
  created() {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'fundRecDetails-dialog',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    defaultHeaders: [
      { text: 'Type', value: 'type' },
      { text: 'Description', value: 'description' },
      { text: 'Amount', value: 'amount' },
      { text: 'Memo', value: 'memo' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'index']),
    ...mapState({
      fundReconTypes: (state) => state.enums.fundReconTypeEnum || [],
    }),
    headers() {
      return this.defaultHeaders;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    editedItem: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    sumAmount() {
      let totalSum = 0.00;
      for (let i = 0; i < this.editedItem.length; i += 1) {
        totalSum += amountToNumber(this.editedItem[i].amount);
      }
      return totalSum;
    },
  },
  methods: {
    amountFormat,
    amountToNumber,
    focusAmount,
    loadInitialValues(item) {
      return {
        ...item,
        amount: amountFormat(amountToNumber(item.amount)),
      };
    },
    addOne() {
      this.$emit('input', [
        ...this.editedItem,
        {
          ...defaultItem,
        },
      ]);
      return true;
    },
    somethingChanged() {
      this.$emit('input', this.editedItem);
    },
    removeOne(item) {
      this.$emit('input', [
        ...this.editedItem.filter((candidate) => item !== candidate),
      ]);
    },
    updateBalance() {
      this.$emit('updateBalance');
    },
  },
};
</script>

<style lang="sass">
  .eft-rec-amount input
    text-align: right
</style>
